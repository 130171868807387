@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap");

html {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
}

nav {
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
}

@layer components {
  .nav-list {
    @apply p-2 hover:text-main-color border-b-4 border-transparent hover:border-main-color;
    transition: 0.25s ease-in-out;
  }

  .mobile-nav-list {
    @apply p-2 my-1;
    transition: 0.25s ease-in-out;
  }

  .text-based-form {
    @apply p-3 mb-4 border rounded border-gray-400;
  }

  #id-card::-webkit-file-upload-button {
    /* visibility: hidden; */
  }
}

/* Hide scrollbar for Chrome, Safari and Opera */
.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hide-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
